import { StateToken } from "@ngxs/store";
import { Principal } from "@sod/core/index";
import { VideoResolution } from "@sod/models/enum-view";

export const STATE_TOKEN = new StateToken<StateModel>('sod');
export interface StateModel {
  token: string;
  userId: number;
  site: 'admin' | 'learning';
  videoResolution: VideoResolution[] | null,
  settingVideoResolution: VideoResolution[] | null
}

export const initialState: StateModel = {
  token: '',
  userId: -1,
  site: 'learning',
  videoResolution: null,
  settingVideoResolution: null
};
